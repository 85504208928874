import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"; deckDeckGoHighlightElement();

export default ({ data }) => {
  const { frontmatter, body, excerpt} = data.mdx;
  return (
    <>
      <SEO title={frontmatter.title} description={excerpt}/>
      <Layout>
        <div className="relative py-5 overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto mb-6">
              <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">{frontmatter.title}</h1>
              <div className="flex justify-between">
                <p className=" text-gray-900 leading-8 font-semibold">{frontmatter.author}</p>
                <p className=" text-gray-900 leading-8">{frontmatter.date}</p>
              </div>
              <div className="my-5 border-b"></div>
            </div>
            <div className="prose prose-lg text-gray-900 mx-auto">
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query PostsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt(pruneLength: 250)
      frontmatter {
        title
        date(formatString: "Do MMMM YYYY")
        author
      }
    }
  }
`;